
export default {
  data: () => ({
    featuredCategories: []
  }),
  fetchOnServer: false,
  async fetch () {
    this.featuredCategories = await this.$backend.featured_categories.list()
  }
}
